import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import { Message } from "element-ui";

export function contactNumberValidation(numbers, type) {
  let invalids = [];
  let valids = [];
  // let regex = /^(?:\d{3}|\(\d{3}\))([-\/\.])\d{3}\1\d{4}$/; ###-###-####
  let types = {
    Mobile: {
      regex: /^\d{10}$/,
    },
    Office: {
      regex: /^\d{7}$/,
    },
    Home: {
      regex: /^\d{5}$/,
    },
  };
  let regex = types[type].regex;
  numbers.forEach((num, index) => {
    var OK = regex.exec(num);
    if (!OK) {
      invalids.push(index);
      return;
    }
    valids.push(num);
  });
  invalids.forEach((idx) => {
    let num = numbers[idx];
    Message({
      type: "error",
      showClose: true,
      message: `${num} is not a valid ${type} Number.`,
    });
  });
  return valids;
}

export function formatDate(date, format) {
  if (date) {
    if (date.length < 5) {
      return "N/A";
    }
    if (format) {
      return moment(String(date)).format(format);
    } else {
      return moment(String(date)).fromNow();
    }
  }
  return false;
}
export function handleError(tempError, httpError, showMessage = true,scrollTop = true) {
  if (showMessage) {
    Message({
      type: "error",
      showClose: true,
      message: `One or more fields have an error. Please check and try again.`,
    });
   }
  // Error 😨
  console.log({ httpError });
  if (httpError.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    console.log("RESPONSE ERROR:", httpError.response);
    tempError.responseError.status = httpError.response.status;
    tempError.responseError.data = httpError.response.data;
  } else if (httpError.request) {
    /*
     * The request was made but no response was received, `httpError.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     */
    console.log("REQUEST ERROR:", httpError.request);
    tempError.requestError.status = httpError.request.status;
    tempError.requestError.data = httpError.request.data;
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("CLIENT ERROR", httpError.message);
    tempError.clientError = httpError.message;
  }
  return tempError;
}
export function formatAmount(amount) {
  if (amount) {
    let numberFormat2 = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "NRP",
    });
    return numberFormat2.format(amount);
  } else {
    return 0;
  }
}



export function removeObjProp(object, keys) {
  for (const prop of keys) {
    Vue.delete(object, prop);
  }
  return object;
}


export function joinListBy(list, delimiter) {
  delimiter = delimiter || ", ";
  if (Array.isArray(list)) {
    return list.join(delimiter);
  }
  return list;
}


export const get_error_schema = () => {
  return {
    requestError: {
      status: "",
      data: "",
    },
    responseError: {
      status: "",
      data: "",
    },
    clientError: "",
  };
};

export function replace_(text) {
  if (typeof text === "string") {
    return text.replaceAll("_", " ");
  }
  return text;
}

export function capitalizeText(text) {
  if (!text) {
    return ""
  }
  if (_.isNumber(text)) {
    return text;
  }
  return _.startCase(text);
}
export function capitalizeFirst(text) {
  if (!text) {
    return false;
  }
  return _.capitalize(text);
}
export function limitTo2Decimal(amount, length = 0) {
  if (typeof amount === 'number') {
    amount = String(amount);
  }
  if (length && amount.length > length) {
    amount = amount.slice(0, length);
  }
  return amount.indexOf(".") >= 0
    ? amount.substr(0, amount.indexOf(".")) +
        amount.substr(amount.indexOf("."), 3)
    : amount;
}

export const getRandomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

export default {
  handleError,
  limitTo2Decimal,
  formatDate
};
