export const tenantRoutes = [
  {
    path: "/tenant",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/",
        name: "tenant-dashboard",
        component: () => import("../pages/dashboard.vue"),
        meta: {
          title: "Tenant Dashboard",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "all",
        name: "tenant-list",
        component: () => import("../pages/list.vue"),
        meta: {
          title: "Tenant List",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "create",
        name: "tenant-create",
        component: () => import("../pages/create.vue"),
        meta: {
          title: "Tenant Create",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "detail/:tenant_uuid",
        name: "tenant-detail",
        component: () => import("../pages/detail.vue"),
        props:true,
        meta: {
          title: "Tenant Detail",
          icon: "menu",
          noCache: true,
        },
      },
    ],
  },
];
