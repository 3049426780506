import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import "./assets/sass/main.scss";
// import "./permission"; // permission control


import "./global/globalPlugins";
// import 'bootstrap/dist/css/bootstrap.css';
import "./global/fontAwesomeIconLibrary";
import "./global/globalMixins.js";
import "./global/globalFilters";
import "./global/globalDirectives";
import "./global/globalComponents";
import "./sentryInitialization";

global.$iconColorAll = "#43A047";
global.$router = router;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
