export default {
  computed: {
    titleError() {
      return this.handleServerError('title')
    },
    bioError() {
      return this.handleServerError('bio')
    },
    noteError() {
      return this.handleServerError('note')
    },
    // address errors
    addressLine1Error() {
      return this.handleServerError('address_line_1')
    },
    cityError() {
      return this.handleServerError('city')
    },
    countryError() {
      return this.handleServerError('country')
    },
    nameError() {
      return this.handleServerError('name')
    },
    companyTypeError() {
      return this.handleServerError('company_type')
    },
    // client registration errors
    businessNameError() {
      return this.handleServerError('business_name')
    },
    accountAliasNameError() {
      return this.handleServerError('account_alias_name')
    },
    businessCategoryError() {
      return this.handleServerError('business_category')
    },
    companyNameError() {
      return this.handleServerError('company_name')
    },
    currencyError() {
      return this.handleServerError('currency')
    },
    passwordAgainError() {
      return this.handleServerError('password_again')
    },
    passwordError() {
      return this.handleServerError('password')
    },
    timezoneError() {
      return this.handleServerError('timezone')
    },
    usernameError() {
      return this.handleServerError('username')
    },
    contactNameError() {
      return this.handleServerError('contact_name')
    },
    emailError() {
      return this.handleServerError('email')
    },
    contactNumberError() {
      return this.handleServerError('contact_number')
    },
    estimatedAnnualTurnoverError() {
      return this.handleServerError('estimated_annual_turnover')
    },
    noOfStoresError() {
      return this.handleServerError('no_of_stores_you_have')
    },
    noOfBusinessesYouHaveError() {
      return this.handleServerError('no_of_businesses_you_have')
    },
    noOfEmployeesError() {
      return this.handleServerError('no_of_employees')
    },
    noOfUsersError() {
      return this.handleServerError('no_of_users')
    },
    businessTypeError() {
      return this.handleServerError('business_type')
    },
    businessDisplayNameError() {
      return this.handleServerError('business_display_name')
    },
    designationError() {
      return this.handleServerError('designation')
    },
    lastNameError() {
      return this.handleServerError('last_name')
    },
    middleNameError() {
      return this.handleServerError('middle_name')
    },
    firstNameError() {
      return this.handleServerError('first_name')
    },
    accountNameError() {
      return this.handleServerError('account_name')
    },

    // payment
    paymentDateError() {
      return this.handleServerError('payment_date')
    },
    paymentAmountError() {
      return this.handleServerError('payment_amount')
    },
    paymentMethodError() {
      return this.handleServerError('payment_method')
    },
    paymentMethodDetailError() {
      return this.handleServerError('payment_method_detail')
    },
    referenceNoError() {
      return this.handleServerError('reference_no')
    }
  },
  methods: {
    handleServerError() {
      return ''
    },
    handleItemLinesError() {
      return ''
    },
  },
}
