<template>
  <div class="d-flex align-items-center">
    <div class="relative">
      <span v-if="date">
        {{ date | formatDate() | capitalizeText }}
      </span>
      <span v-else>N/A</span>
    </div>
    <div class="icon pl-2">
      <el-tooltip
        placement="top"
        effect="dark"
        trigger="hover"
        :content="absoluteDate"
      >
        <i
          v-if="date"
          class="el-icon-info cursor-pointer date"
          :style="iconStyle"
        ></i>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/util";
export default {
  name: "displayDate",
  props: {
    date: {
      type: String,
      required:true
    }
  },
  methods: { formatDate },
  computed: {
    absoluteDate() {
      return this.formatDate(this.date, "D MMM YYYY, h:mm a").toString()
    },
  },
  data() {
    return {
      iconStyle: {
        "font-size": ".70rem",
      },
    };
  },
};
</script>

<style></style>
