export function user_auth_token(state) {
  if (state.auth_data) {
    return state.auth_data.token;
  }
}
export function user_avatars(state) {
  if (state.user) {
    return state.user.avatars;
  }
  return {}
}
export function user_email(state) {
  if (state.user) {
    return state.user.email;
  } else {
    return "";
  }
}
export function user_primary_contact_number(state) {
  if (state.user) {
    return state.user.primary_contact_number;
  } else {
    return "";
  }
}
export const user_username = (state) => {
  if (state.user) {
    return state.user.username;
  }
};
export const user_last_login = (state) => {
  if (state.user) {
    return state.user.last_login;
  }
};
export const user_full_name = (state) => {
  if (state.user) {
    return state.user.name;
  }
};
export const user_uuid = (state) => {
  if (state.user) {
    return state.user.uuid;
  }
};
export const user_account_uuid = (state) => {
  if (state.user) {
    return state.user.user.uuid;
  }
};
export const user_company_name = (state) => {
  if (state.user) {
    return state.user.my_company_profile.business_name;
  }
};
export const user_company_uuid = (state) => {
  if (state.user) {
    return state.user.my_company_profile.uuid; 
  }
};
export const user_company_data = (state) => {
  if (state.user) {
    return state.user.my_company_profile;
  }
};

export const user_employee_data = (state) => {
  if (state.user_employee_data) {
    return state.user_employee_data;
  } else {
    return {};
  }
};

export const erp_client_data = state => {
  if (state.erp_client) {
    return state.erp_client;
  }
  return {}
}

export const is_primary_user = state => {
  if (state.isPrimaryUser) {
    return state.isPrimaryUser;
  }
}
export const tenant_subscription_data = state => {
  if (state.erp_client && state.erp_client.subscription) {
    return state.erp_client.subscription;
  }
  return null
}