const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("./../components/login"),
    title: "Login",
    icon: "fa-users",
  },
  {
    path: "/authredirect",
    component: () => import("./../components/login/authredirect"),
    hidden: true,
  },
];

export default routes;
