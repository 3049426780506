<template>
  <div :class="[{'text-center':inCenter}]">
    <el-tooltip
      class="item"
      effect="dark"
      :content="content"
      placement="bottom"
    >
      <!-- content="Invoice Order List" -->
      <el-button
        size="small"
        type="primary"
        icon="el-icon-d-arrow-left"
        class="mr-2"
        @click="$router.push({ name: routeName })"
        >
        {{
          displayText
        }}
          </el-button
      >
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: true,
    },
    content:{
      type:String,
      required:true,
    },
    displayText:{
      type:String,
      required:true
    },
    inCenter:{
      type:Boolean,
      required:false,
      default:false
    }
  },
};
</script>

<style>
</style>