<template>
  <el-alert type="error" center :show-icon="false">
    <div class="h3">This page is currently in development.</div>
  </el-alert>
</template>

<script>
export default {

}
</script>

<style>

</style>