import Vue from "vue";

import util from "../util";
import $axios from "../axios-config/index";
import DisplayMessage from "../util/Message";
import urls from '@/config/urls';
import appConstants from '@/constants/AppConstants';
import formRules from '@/util/formRules';
import _ from "lodash";

import InfiniteLoading from "vue-infinite-loading";

// set $axios as global axios especially to get access in Vuex store beacause $axios is used as prototype only in vue instance
global.$http = $axios;
global.$msg = DisplayMessage

Vue.use(InfiniteLoading, {
  props: {
    spinner: "spiral",
    /* other props need to configure */
  },
});

const plugin = {
  install() {
    Vue.prototype.$helpers = util;
    Vue.prototype.$msg = DisplayMessage;
    Vue.prototype.$http = $axios;
    Vue.prototype.$urls= urls;
    Vue.prototype.$appConstants = appConstants;
    Vue.prototype.$formRules = formRules
  },
};

Vue.use(plugin);

Object.defineProperty(Vue.prototype, "$_", { value: _ });
