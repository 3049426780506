var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.error &&
    (_vm.error.clientError ||
      _vm.error.responseError.status ||
      _vm.error.responseError.data ||
      _vm.error.requestError.status ||
      _vm.error.requestError.data)
  )?_c('el-alert',{staticClass:"my-2",attrs:{"type":"error","closable":_vm.closable,"show-icon":_vm.showIconInErrorMessage},on:{"close":_vm.handleAlertClose}},[_c('template',{slot:"title"},[_vm._v("Please fix the following errors at "+_vm._s(_vm.title)+" ")]),[(_vm.error.clientError)?_c('div',[_c('pre',{staticClass:"text-danger"},[_vm._v("      "+_vm._s(_vm.error.clientError)+"\n          ")])]):_vm._e(),(_vm.error.responseError.status || _vm.error.responseError.data)?_c('ul',{style:(_vm.ulPadding)},[(_vm.error.responseError.data.length != 1)?_c('div',_vm._l((_vm.error.responseError.data),function(value,key){return _c('li',{key:key},[(key !== 'item_lines' && key !== 'rows' && key !== 'lines')?_c('div',[_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(key ? key.replaceAll("_", " ") : key)+" ")]),_vm._v(" : "),(typeof value == 'string')?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):(typeof value[0] == 'string')?_c('span',[_vm._v(" "+_vm._s(value[0])+" ")]):_c('ul',[_c('li',_vm._l((value),function(value,key){return _c('div',{key:key},[(key.length > 0)?_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(key.length < 2 ? parseInt(key) + 1 : key.replaceAll("_", " "))+" : ")]):_vm._e(),_c('span',{domProps:{"innerHTML":value[0] ? value[0] : _vm.handleDisplayError(value)}})])}),0)])]):_c('div',[_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(key ? key.replaceAll("_", " ") : key)+" ")]),(typeof value[0] === 'string')?_c('span',[_vm._v(" "+_vm._s(value[0])+" ")]):_c('nested-item-line-error-display',{attrs:{"error":value}})],1)])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.error.responseError.data[0])+" ")])]):_vm._e(),(_vm.error.requestError.status && _vm.error.requestError.data)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.error.requestError.status))]),_vm._v(" - "),_c('pre',{staticClass:"text-danger"},[_vm._v("          "+_vm._s(_vm.error.requestError.data)+"\n          ")])]):_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }