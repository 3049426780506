export const dashboardRoutes = [
  {
    path: "/dashboard",
    component: () => import("@/layout/"),
    children: [
      {
        path: "/",
        name: "main-dashboard",
        component: () => import("../pages/index.vue"),
        meta: {
          title: "Main Dashboard",
          icon: "menu",
          noCache: true,
        },
      },
    ],
  },
];
