export default {
  name: "DeleteObjectMixin",
  data() {
    return {
      showCloseValue: true,
    };
  },
  computed: {
    showCloseIcon() {
      return this.showCloseValue;
    },
  },
  methods: {
    async deleteObject(
      modelName,
      object,
      url,
      dataList,
      index,
      redirect_route_name
    ) {
      let objectName = object.sweet_name;
      if (object.hasOwnProperty("sweetName")) {
        objectName = object.sweetName;
      } else if (object.hasOwnProperty("sweet_name")) {
        objectName = object.sweet_name;
      } else if (object.hasOwnProperty("name")) {
        objectName = object.name;
      } else if (object.hasOwnProperty("fulfillment_number")) {
        objectName = object.fulfillment_number;
      } else if (object.hasOwnProperty("order_number")) {
        objectName = object.order_number;
      }
      return await this.$confirm(
        `<h4 class="text-danger">Are you sure?</h4>
        <div class="text-left">
         Are you sure you want to delete the selected ${modelName}? All of the following objects and their related items will be deleted:
         <h6>Summary</h6>
          <ul>
            <li>${modelName} : 1 </li>
          </ul>
          <h6>Objects</h6>
          <ul>
            <li>${modelName}: ${objectName}</li>
          </ul>
        </div>`,
        {
          confirmButtonText: "OK",
          dangerouslyUseHTMLString: true,
          cancelButtonText: "Cancel",
          cancelButtonClass: "el-button--danger is-plain",
          center: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showCloseValue: this.showCloseIcon,
        }
      )
        .then(async () => {
          if (dataList[index] && dataList[index].hasOwnProperty("can_delete")) {
            dataList[index].can_delete = false;
          }
          if (object && object.hasOwnProperty('isLoading')) {
            object.isLoading = true;
          }
          this.showCloseValue = false;
          return await $http
            .remove({
              url: url,
            })

            .then((response) => {
              if (!response) {
                let message = "";
                message = "Unable to delete the item at the moment.";
                if (modelName && objectName) {
                  message = `Unable to delete ${objectName} [ ${modelName} ] at the moment.`;
                }
                throw new Error(message);
              }

              if (dataList.length > 0 && index > -1) {
                dataList.splice(index, 1);
              } else if (redirect_route_name) {
                this.$router.push({
                  name: redirect_route_name,
                });
              }
              this.$message({
                message:
                  "Successfully delete  " + modelName + ": " + objectName,
                type: "success",
              });
              this.showCloseValue = true;
              return true;
            })
            .catch((error) => {
              if (
                dataList[index] &&
                dataList[index].hasOwnProperty("can_delete")
              ) {
                dataList[index].can_delete = true;
              }
              if (
                dataList[index] &&
                dataList[index].hasOwnProperty("isLoading")
              ) {
                dataList[index].isLoading = false;
              }
              throw error;
            });
        })
        .catch((error) => {
          this.showCloseValue = true;
          if (object && object.hasOwnProperty('isLoading')) {
            object.isLoading = false;
          }
          if (error === "cancel") {
            this.$message({
              type: "info",
              message: "Delete canceled",
            });
            return false;
          }
          if (error) {
            throw error;
          }
        });
    },
  },
};
