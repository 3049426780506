<template>
  <div id="base-table">
    <div class="row justify-content-between d-flex align-items-end">
      <div class="col-8">
        <div class="page-title" v-if="tableName && showTableName">
          {{ tableName }}
        </div>
        <div class="page-subtitle" v-if="tableSubtitle && showTableName">
          {{ tableSubtitle }}
        </div>
      </div>
    </div>
     <nested-error-message
      closable
      :error="error"
      :title="' [ ' + tableName + ' ] '"
    />
    <slot name="error"></slot>
    <el-card class="mb-2" :body-style="elCardBodyStyle" >
      <div id="advanced-filter-options" v-if="!noAdvancedFilter">
        <div class="row ">
          <div class="col-lg-10">
            <el-radio-group
              class="w-100 d-flex h-scroller"
              style="max-width: 100%; overflow-x: auto"
              v-model="advancedFilter.status"
              size="small"
              text-color="white"
              fill="green"
              @change="handleStatusFilterChange"
            >
              <el-radio-button
                v-for="(filter, index) in filters"
                :key="index"
                :label="filter.label"
              >
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-lg-2 d-flex">
            <el-button
              class="w-100 m-0"
              type="primary"
              :size="inputSize"
              @click="setAdditionalParams"
              :disabled="tableLoading"
              >Apply filters</el-button
            >
            <el-button
              class="w-100 m-0"
              type="danger"
              :size="inputSize"
              @click="clearAllFilters"
              :disabled="tableLoading"
              >Clear filters</el-button
            >
          </div>
        </div>
        <el-row :gutter="20" align="bottom">
          <el-col :lg="5" class="mt-lg-0 mt-2">
            <el-input
              :size="inputSize"
              clearable
              :placeholder="inputPlaceholder"
              class="filterProduct search-filter-outline"
              v-model="advancedFilter.search"
              :disabled="tableLoading"
            >
            </el-input>
          </el-col>
          <el-col :lg="4" class="mt-lg-0 mt-2" v-if="0 === 1">
            <el-select
              :size="inputSize"
              class="w-100"
              clearable
              v-model="advancedFilter.store"
              placeholder="Select Store"
              v-if="storeOptions.length"
            >
              <el-option
                v-for="store in storeOptions"
                :key="store.uuid"
                :label="store.sweet_name"
                :value="store.uuid"
              >
              </el-option>
            </el-select>
            <no-options-from-vuex
              v-else
              message="No Store options found."
            ></no-options-from-vuex>
          </el-col>
        </el-row>
      </div>
        <el-card v-loading="tableLoading" :body-style="elCardBodyStyle">
          <el-button
            :size="inputSize"
            v-if="showCreateButton && permission"
            round
            class="is-round float-left btn-lg el-button el-button--primary mr-2"
            @click="$emit('onClickAdd')"
            :disabled="tableLoading"
          >
            Add {{ tableName }}
            <font-awesome-icon
              class="text-center"
              icon="plus"
            ></font-awesome-icon>
          </el-button>
          <export-to-c-s-v
            v-if="csvExport"
            :data="getCSVData"
            :file_name="csvExport && csvExport.fileName"
            class="float-right cursor-pointer"
            :hideIcon="tableLoading || !getList.length"
          ></export-to-c-s-v>
          <el-row class="mt-3">
            <!-- border -->
            <el-table
              size="mini"
              stripe
              :element-loading-text="loadingText"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="getList"
              row-key="uuid"
              :lazy="hasTree"
              :load="load"
              style="width: 100%"
              :tree-props="treeProps"
              @row-click="handleRowClick"
              :header-cell-class-name="headerRowClassName"
              @sort-change="handleTableSort"
              :empty-text="tableEmptyText"
            >
              <slot></slot>
              <el-table-column
                label="Tenant"
                :width="large2"
                v-if="is_local_development"
              >
                <template slot-scope="scope">
                  Tenant:
                  <span v-if="scope.row.company">
                    {{ scope.row.company.obj_repr }}
                  </span>
                  <br />
                  Site
                  <span v-if="scope.row.site">
                    {{ scope.row.site.name }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <lp-pagination
              v-if="resultList.hasOwnProperty('results')"
              :objectList.sync="resultList"
              :pageSize="pageSize"
              @onCurrentChange="getNextPreviousCurrentPageList"
            />
          </el-row>
        </el-card>
    </el-card>
  </div>
</template>
<script>
import ExportToCSV from "@/components/CSV/ExportToCSV";
import { getExportJson } from "@/util/ExportUtils";

export default {
  name: "base-table",
  components: {
    ExportToCSV,
    lpPagination: () => import("@/components/Pagination"),
  },
  mixins: [],
  watch: {
    value(nv) {
      this.resultList = nv;
    },
    searchQuery(nv, ov) {
      if (ov && !nv) {
        // this.params = {}
        this.getAll();
      }
    },
    explicitLoading: {
      handler: function (nv, ov) {
        this.tableLoading = nv;
      },
      immediate: true,
    },
  },
  computed: {
    getList() {
      if (this.resultList.hasOwnProperty("results")) {
        return this.resultList.results;
      } else {
        return this.resultList;
      }
    },
    getCSVData() {
      if (
        this.resultList &&
        this.resultList.results &&
        this.csvExport &&
        this.csvExport.columns
      ) {
        return getExportJson({
          columns: this.csvExport.columns,
          data: this.resultList.results,
        });
      }
      return [];
    },
  },
  mounted() {
    if (!this.url) {
      return;
    }
    this.getAll();
    this.$root.$on("handleCustomFilter", (payload) =>
      this.handleCustomFilter(payload)
    );
  },
  data() {
    return {
      isTable: true,
      advancedFilter: {
        store: null,
        payment_status: null,
        other: null,
        customer__uuid: null,
        vendor__uuid: null,
        category__uuid: null,
        brand__uuid: null,
        product_variant__purchase_this: null,
        product_variant__sell_this: null,
        status: "All",
        return_type: null,
        search: null,
        is_active: null,
        is_verified: null,
        item_sku: null,
        customer_type: null,
        user_type: null,
        industry: null,
        vendor_type: null,
        supply_type: null,
        primary_business_sector: null,
        payment__uuid: null,
        adjustment_reason__uuid: null,
      },
      showFilterDialog: false,
      activeFilter: "noActiveFilter",
      searchQuery: "",
      params: {
        page: 1,
        pages: 1,
      },
      resultList: this.value,
      tableLoading: false,
      error: this.get_error_schema(),
      options: {
        userType: [
          {
            label: "Individual",
            value: "Individual",
          },
          {
            label: "Company",
            value: "Company",
          },
        ],
        customer: [],
        vendor: [],
      },
      remoteSearch: {
        customer: {},
        vendor: {},
      },
      deliveryFilterValue: "All",
    };
  },
  methods: {
    /* ifi(name) {
      // ifi = is filter included ?
      return (
        this.advancedFilterOptions.length &&
        this.advancedFilterOptions.includes(name)
      );
    }, */
    handleStatusFilterChange() {
      this.setAdditionalParams();
      this.getAll();
    },
    clearAllFilters() {
      Object.keys(this.advancedFilter).forEach((el) => {
        this.advancedFilter[el] = null;
      });
      this.remoteSearch = {
        customer: {},
        vendor: {},
      };
      this.params = {
        page: 1,
        pages: 1,
      };
      this.getAll();
    },
    handleCustomFilter({ data, name, key }) {
      this.activeFilter = name;
      this.getFilteredProduct(key, data.uuid);
    },
    handleTableSort({ column, prop, order }) {
      this.params.page = 1;
      this.params.pages = 1;
      this.params["ordering"] = order === "descending" ? "-" + prop : prop;
      this.getAll();
    },
    load(tree, treeNode, resolve) {
      this.$emit("onLoadChildTreeData", { tree, treeNode, resolve });
    },
    handleRemoveFilter() {
      this.activeFilter = "noActiveFilter";
      this.params = {};
      this.getAll();
    },
    handleFilterCommand(filter) {
      if (filter.key === "advance") {
        this.showFilterDialog = true;
        return;
      }
      this.onSelectFilterBar(filter.key, filter.value, filter.name);
    },
    headerRowClassName({ row, rowIndex }) {
      return `table-header`;
    },
    handleRowClick(args) {
      this.$emit("row-click", args);
    },
    async getAll() {
      if (this.url) {
        try {
          this.$emit("performingActionInTheTable");
          this.error = this.get_error_schema();
          this.tableLoading = true;
          const response = await this.$http.get({
            url: this.url,
            params: this.params,
          });

          if (!response) {
            throw new Error(
              `Unable to get ${this.tableName}'s list at the moment.`
            );
          }
          const { data } = response;

          this.resultList = Object.assign({}, data);
          if (data.hasOwnProperty("results")) {
            this.resultList.results = Object.assign(
              [],
              this.resultList.results
            );
          } else {
            this.resultList["results"] = [...data];
          }
          // this.resultList = []
          this.$emit("input", this.resultList);
        } catch (error) {
          console.log("error base table: ", error);
          this.resultList["results"] = [];
          this.$helpers.handleError(this.error, error);
        } finally {
          this.tableLoading = false;
        }
      }
    },
    setAdditionalParams() {
      const filters = this.advancedFilter;
      this.params = {
        page: 1,
        pages: 1,
      };
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null && filters[key] !== "" && filters[key]) {
          this.params[key] = filters[key];
        }
      });
      if (this.params["status"] === "All") {
        delete this.params["status"];
      }
      this.getAll();
    },
    onSelectFilterBar(filterKey, filterValue, filterName) {
      this.activeFilter = filterName;
      this.searchQuery = "";
      this.getFilteredProduct(filterKey, filterValue);
    },
    onSearchQuery(value, buttonClick = false, validIfEmpty) {
      if (!value && buttonClick) {
        this.$alert("Enter the text to search with.", "Invalid action", {
          confirmButtonText: "OK",
        })
          .then(() => true)
          .catch(() => true);
      } else {
        if (validIfEmpty) {
          this.activeFilter = "noActiveFilter";
          this.getFilteredProduct("search", value);
        } else if (!validIfEmpty && value) {
          this.activeFilter = "noActiveFilter";
          this.getFilteredProduct("search", value);
        } else {
          return false;
        }
      }
    },
    getFilteredProduct(filterKey, filterValue) {
      delete this.params.page;
      var obj = {};
      if (filterValue) {
        obj[filterKey] = filterValue;
      } else {
        delete this.params[filterKey];
      }
      this.params = {
        ...this.params,
        ...obj,
      };
      this.getAll();
    },
    getNextPreviousCurrentPageList(pageNumber) {
      if (!this.url) {
        // for the user's task dashboard table
        this.$emit("on-paginate", pageNumber);
        return;
      }
      this.params.page = pageNumber;
      this.getAll();
    },
    handleAdvanceFilter(filters) {
      // this.params = {}
      Object.keys(filters).forEach((key) => {
        this.params[key] = filters[key];
      });
      this.getAll();
    },
  },
  props: {
    noAdvancedFilter: {
      type: Boolean,
      default: false,
    },
    inputPlaceholder: {
      type: String,
      default: "Search here",
    },
    permission: {
      //permission to add(button) for different module
      type: Boolean,
      default: false,
    },
    hasTree: {
      type: Boolean,
      required: false,
      default: false,
    },
    treeProps: {
      type: Object,
      required: false,
      // default: {children: 'children', hasChildren: 'hasChildren'}
    },
    tableName: {
      type: String,
      required: false,
    },
    tableSubtitle: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    value: {
      type: null,
    },
    loadingText: {
      type: String,
      default: "Loading .....",
    },
    filters: {
      type: Array,
    },
    pageSize: {
      type: Number,
      default: 10,
      required: false,
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    csvExport: {
      type: Object,
    },
    explicitLoading: {
      type: Boolean,
      default: false,
    },
    showTableName: {
      type: Boolean,
      default: true,
    },
    advancedFilterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    tableEmptyText: {
      type: String,
      default: "No items"
}
  },
};
</script>

<style lang="scss" scoped>
.el-table .table-header {
  color: #023129;
}
</style>
