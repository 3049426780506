export default {
  auth_data: JSON.parse(localStorage.getItem("saas_admin_auth")) || null,
  user: JSON.parse(localStorage.getItem("saas_admin_user")) || null,
  user_employee_data:
    JSON.parse(localStorage.getItem("user_employee_data")) || null,
  isPrimaryUser: JSON.parse(localStorage.getItem("saas_admin_user"))
    ? JSON.parse(localStorage.getItem("saas_admin_user")).is_primary_user
    : false,
  erp_client: JSON.parse(localStorage.getItem('saas_admin_erp_client')) || null,
};
