export default {
  computed: {
    twImage() {
      return this.rtwImage();
    },
    large1() {
      return this.rtwlarge1();
    },
    large2() {
      return this.rtwlarge2();
    },
    large3() {
      return this.rtwlarge3();
    },
    large4() {
      return this.rtwlarge4();
    },
    twDate() {
      return "120px";
    },
  },
  methods: {
    rtwImage() {
      return this.isMobile ? 120 + "px" : 120 + "px";
    },
    rtwlarge1() {
      return this.returnWidth(150, 100);
    },
    rtwlarge2() {
      return this.returnWidth(200, 150);
    },
    rtwlarge3() {
      return this.returnWidth(250, 200);
    },
    rtwlarge4() {
      return this.returnWidth(300, 250);
    },
    returnWidth(large, small) {
      return this.isMobile ? small + "px" : large + "px";
    },
  },
};
