import _ from "lodash";

const app = {
  state: {
    appConstants: {},
    appPermissions: {},
    httpException: {
      isHttpException: false,
      httpExceptionDetail: {},
    },
  },
  getters: {
    app_constants: (state) => state.appConstants,
    app_permissions: (state) => state.appPermissions,
  },
  mutations: {
    SET_APP_CONSTANTS: (state, payload) => {
      state.appConstants = payload;
    },
    SET_APP_PERMISSIONS: (state, payload) => {
      state.appPermissions = payload;
    },
    SET_HTTP_EXCEPTION: (state, payload) => {
      state.httpException.isHttpException = payload.isHttpException;
      state.httpException.httpExceptionDetail = payload.httpExceptionDetail;
    },
  },
  actions: {
    setAppConstants({ commit, state }, payload) {
      if (_.isEmpty(state.appPermissions)) {
        commit("SET_APP_CONSTANTS", payload);
      }
    },
    setAppPermissions({ commit, state }, payload) {
      if (_.isEmpty(state.appPermissions)) {
        commit("SET_APP_PERMISSIONS", payload);
      }
    },
    setHttpException({ commit }, payload) {
      commit("SET_HTTP_EXCEPTION", payload);
    },
  },
};

export default app;
