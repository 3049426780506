export const baseApiUrl = "/api/v1/";
export const loginUrl = baseApiUrl + "auth/login/";
export const signupUrl = baseApiUrl + "signup/";
export const profileUrl = baseApiUrl + "users/me/";

function urlFormatter(url) {
    url = url.trim();
    url = url.charAt(0) === "/" ? url.slice(1) : url;
    return `${baseApiUrl}${url}`;
}

const auth = {
    loginUrl: urlFormatter("auth/login/"),
    signupUrl: urlFormatter("auth/signup/"),
};

const globalSetup = {
    plans: urlFormatter("global-setup/plans/"),
    calculatePlanCostTotal: urlFormatter("global-setup/plan-costs/")
}

const subscription = {
    getList: urlFormatter("saas-admin/tenant-subscription/subscriptions/"),
    createNewSubscription: urlFormatter("saas-admin/tenant-subscription/subscriptions/create/"),
    getSubscriptionDetail: (tenant_subscription_uuid) => urlFormatter(`saas-admin/tenant-subscription/subscriptions/${tenant_subscription_uuid}/`),
    continueSubscription: (tenant_subscription_uuid) => urlFormatter(`saas-admin/tenant-subscription/subscriptions/${tenant_subscription_uuid}/continue/`)
}

const tenant = {
    createNewTenant: urlFormatter("client-signup/"),
    getTenantList: urlFormatter("saas-admin/tenant/tenants/"),
    getTenantDetail: (tenant_uuid) => urlFormatter(`saas-admin/tenant/tenants/${tenant_uuid}/`),
    activateTenant: (tenant_uuid) => urlFormatter(`saas-admin/tenant/tenants/${tenant_uuid}/activate-account/`)
}

const subscriptionInvoices = {
    makePayment: (tenant_subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/invoices/${tenant_subscription_invoice_uuid}/make-full-payment/`),
    getPaymentForInvoice: (subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/payments/?invoice=${subscription_invoice_uuid}`),
    getList: urlFormatter("saas-admin/tenant-subscription/invoices/"),
    getDetail: (tenant_subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/invoices/${tenant_subscription_invoice_uuid}/`),
    pdfDownload: (tenant_subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/invoices/${tenant_subscription_invoice_uuid}/pdf-download/`),
    pdfPreview: (tenant_subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/invoices/${tenant_subscription_invoice_uuid}/pdf-preview`),
    sendEmail: (tenant_subscription_invoice_uuid) => urlFormatter(`saas-admin/tenant-subscription/invoices/${tenant_subscription_invoice_uuid}/send-email/`),
}

const users = {
    getRemoteSearchUser: function (query) {
        return urlFormatter(`users/users/?remote_search=${query}`);
    },
    getUserDetailLink: function (userId) {
        let url = `users/users/${userId}/`;
        return urlFormatter(url);
    },
    getMyBasicDataLink: function () {
        let url = `users/users/my-data/`;
        return urlFormatter(url);
    },
    updateUserDetailLink: function (userId) {
        let url = `users/users/${userId}/update`;
        return urlFormatter(url);
    },
    getAllUsersLink: function () {
        let url = "users/users/";
        return urlFormatter(url);
    },
    updateUserAccountSettingDetailLink: function (userAccountUUID) {
        let url = `user-account/user-accounts/${userAccountUUID}/update/`;
        return urlFormatter(url);
    },
    getUserSettingDetailLink: function (userUUID) {
        let url = `users/users/${userUUID}/settings`;
        return urlFormatter(url);
    },
    updateUserChangePasswordLink: function (userUUID) {
        let url = `user-account/user-accounts/me/change-password/`;
        return urlFormatter(url);
    },
}

export default {
    globalSetup,
    auth,
    subscription,
    tenant,
    subscriptionInvoices,
    users
}