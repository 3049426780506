import UserStore from '@/store/user'
import router from '@/router/index';
const allRoutes = router.getRoutes();
const MIXED_MODULE = {
  // rules to get atleast a single access.
  appsSettings: [
    'uom.view_measurementunit',
    'inventory.view_stockadjustmentreason',
    'tax.view_tax',
    'business.view_company',
    'store.view_store',
    'role_and_permission.view_customgroup',
    'sales.view_label',
  ],
}

export function RolesAndPermissionValidation({ to, from, next, store }) {
  let isAccessGranted
  isAccessGranted = checkRoutePermissionGranted(to)
  if (!isAccessGranted) {
    console.log({ to })
    console.log('No access for current route.')
    return next({
      name: 'not-authorized',
    })
  }
  console.log({ to })
  return next()
}
export function checkRoutePermissionGranted(route) {
  const isRootUser = UserStore.state.isPrimaryUser
  const userPermissions = JSON.parse(localStorage.getItem('saas_admin_user_permissions')) || [];
  if (isRootUser) {
    return true
  }
  // allow whitelisted routes to navigate.
  let valid = false
  const routePermission = route.meta.permission || null

  if (!routePermission) {
    console.warn('No route permission set for this route.', route)
    return true
  }
  /* if (!userPermissions.length) {
    return false;
  } */
  valid = !!userPermissions.find((el) => el === routePermission)

  return valid
}
export function navigationActionPermitted(name) {
  const isRootUser = UserStore.state.isPrimaryUser
  const userPermissions = JSON.parse(localStorage.getItem('saas_admin_user_permissions')) || [];
  if (isRootUser) {
    return true
  }
  if (!name) {
    console.warn('you missed to provide the permission. DEBUG IT')
    return true
  }
  // TODO :  allow the whitelisted actions.
  if (userPermissions) {
    let valid = false
    valid = userPermissions && !!userPermissions.find((el) => el === name)
    if (!valid) {
      console.warn(`No permission to : "${name}" `)
    }
    return valid
  }
  return false
}

// ip = isPermitted
// permName : if parent is true, the permName is the object of parent route.
export function ip(permName, parent = false) {
  if (Array.isArray(permName)) {
    return getTruthyValueForMixedModules(permName[0])
  }
  if (parent && permName.children) {
    const { children } = permName
    let valid = false
    valid = children.some((child) => ip(child.permission)) || false
    return valid
  }
  return navigationActionPermitted(permName) || false
}

export function getTruthyValueForMixedModules(permission) {
  const data = MIXED_MODULE[permission]
  return !!data.find((permission) => ip(permission))
}


export function isTopNavigationPermitted(payload) {
  const routes = payload.map(el => el.route.name);
  const permissions = routes.map(route => getRoutePermission(route));
  return permissions.some(perm => ip(perm));
}

export function isToolbarRoutePermitted(routeName) {
  const permission = getRoutePermission(routeName);
  return ip(permission);
}


function getRoutePermission(routeName) {
  const route = allRoutes.find(route => route.name === routeName);
  if (!route.meta || !route.meta.permission) {
    console.warn("check the route object for ", route,". Something could be missing.");
  }
  return route.meta.permission || null;
}