export const userProfileRoutes = [
  {
    path: "/user",
    name: "users",
    component: () => import("@/layout"),
    meta: {
      title: "User",
      icon: "menu",
    },
    children: [
      /* {
        path: "users",
        name: "users-list",
        component: () => import("../user/pages/UserList"),
        meta: { title: "All Users", icon: "menu", noCache: true, permission: "users.view_user" },
      }, */
      {
        path: "users/:uuid/",
        name: "user-detail",
        component: () => import("../pages/UserDetail"),
        hidden: true,
        props: true,
        meta: { title: "User Detail", icon: "menu", noCache: true, isValidNavigatorToUserProfile: true },
        children: [
          {
            path: "setting",
            name: "user-setting",
            component: () => import("../pages/UserSetting"),
            props: true,
            meta: { title: "Setting", icon: "menu", noCache: true, },
          },
        ],
      },
    ],
  },
];

