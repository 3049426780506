import Vue from "vue";
// Require the main Sass manifest file

Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
});

Vue.directive("input-focus", {
  inserted: function (el) {
    // Focus the element
    el.querySelector('input').focus();
  },
});

Vue.directive("pointer", {
  bind(el, binding, vnode) {
    el.style.cursor = "pointer";
  },
});
