import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user",[
       "user_email",
       "user_username",
      "user_avatars",
      "user_full_name",
      "user_primary_contact_number",
      "user_auth_token",
      "user_uuid",
      "user_last_login",
      "user_company_name",
      "user_company_uuid",
      "user_employee_data",
      "user_company_data",
      "user_account_uuid",
      "is_primary_user",
    ])
  }
}