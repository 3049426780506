<template>
  <div class="py-1 pl-3 pl-md-4 text-muted pr-2">
    <p class="card-text">
      <el-breadcrumb separator="/" v-if="showBreadcrumbs">
        <el-breadcrumb-item to="/"
          ><font-awesome-icon icon="home"></font-awesome-icon
        ></el-breadcrumb-item>
        <span v-if="breadcrumbs">
          <el-breadcrumb-item
            v-for="breadcrumb in breadcrumbs"
            :key="breadcrumb.name"
          >
            <router-link v-if="breadcrumb.route" :to="breadcrumb.route">
              {{ breadcrumb.name }}
            </router-link>
            <span v-else-if="breadcrumb.name">{{ breadcrumb.name }}</span>
            <span v-else>{{ breadcrumb }}</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="pageTitle">{{
            pageTitle
          }}</el-breadcrumb-item>
        </span>
        <el-breadcrumb-item v-else>{{ $route.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </p>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsIndex",
  props: {
    showBreadcrumbs: {
      type: Boolean,
      default: true,
      required: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
    },
    pageTitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.el-breadcrumb__item {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>
