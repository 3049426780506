import Vue from "vue";

import {
  formatDate,
  joinListBy,
  formatAmount,
  replace_,
  capitalizeText,
  capitalizeFirst,
} from "../util/";

Vue.filter("formatDate", formatDate);
Vue.filter("formatAmount", formatAmount);
Vue.filter("joinListBy", joinListBy);
Vue.filter("replace_", replace_);
Vue.filter("capitalizeText", capitalizeText);
Vue.filter("capitalizeFirst", capitalizeFirst);
