import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/util/auth'
import NProgress from "nprogress";
import authRoute from "@/auth/router";
import { subscriptionRoutes } from '@/modules/Subscription/router';
import { tenantRoutes } from '@/modules/tenant/router';
import { invoiceRoutes} from '@/modules/Invoice/router';
import { dashboardRoutes } from '@/modules/Dashboard/router';
import { userProfileRoutes } from '@/modules/users/router';

import "nprogress/nprogress.css";

Vue.use(VueRouter)
const whiteList = ['/login']

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../modules/Home/HomeView.vue')
  },
   /* {
    path: "*",
    redirect: { name: "login" },
  }, */
]

const routes = baseRoutes.concat(
  authRoute,
  subscriptionRoutes,
  tenantRoutes,
  invoiceRoutes,
  dashboardRoutes,
  userProfileRoutes
)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    // store,
  }
  const token = getToken()
  if (token) {
    // determine if there has token
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // store.commit('SET_USER_ACCESS');
      // if (to.path !== '/not-authorized') {
      //   if (isRouteToUserProfile(to)) {
      //     return next()
      //   }
      //   if (to.matched.some(record => record.meta.isValidNavigatorToUserProfile)) {
      //     // set the access values if needed;
      //    return isValidNavigatorToUserProfile(context);
      //   }
      //   RolesAndPermissionValidation(context)
      // }
      next()
    }
  } else {
      // store.dispatch('auth/logout');
    // has no token
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login') // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
}) 

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});


export default router
