import Vue from "vue";
import router from "./router";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const dsn = process.env.VUE_APP_SENTRY_DSN;
if (dsn && !process.env.VUE_APP_DEBUG) {
  const tracingOrigins = [/^\//]
  if (process.env.VUE_APP_SENTRY_TRACING_ORIGINS) {
    tracingOrigins.push(...process.env.VUE_APP_SENTRY_TRACING_ORIGINS.split(","))
  }
  Sentry.init({
    Vue,
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins
      }),
    ],
    tracesSampleRate: 1.0,
    logErrors: true,
    trackComponents: true,
    attachProps: true,
    attachStacktrace: true,
    timeout: 3000,
    hooks: ["activate", "create", "destroy", "mount", "update"],
  });
} else {
  console.warn("Configure the DSN url to initialize the Sentry loggings.");
}
