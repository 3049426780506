<template>
  <div>
    <div
      class="
        error-wrapper
        d-flex
        flex-column
        justify-content-center
        align-items-center
        position-absolute
      "
    >
      <div class="error-code strong text-orange">OoPS !</div>
      <h1 class="text-white" v-if="status !== 0">{{ status }} - {{ message }}</h1>
      <h5 class="mb-4 text-white text-center" v-html="getDefaultExceptionMessage">
      </h5>
        <el-button v-if="status === 401" class="text-uppercase" type="primary" @click="handleNavigateToLogin"> Login </el-button>
      <router-link to="/" v-else>
        <el-button type="primary" class="text-uppercase"
          >Go To Homepage</el-button
        >
      </router-link>
      <div v-if="defaultData.type === 'setup'">
        {{ defaultData.data }}
      </div>
    </div>
  </div>
</template>

<script>
import "./exceptions.scss";
import {capitalizeText} from '@/util';
export default {
  name: "exceptionsIndex",
  props: [
    "type",
    "data",
    "status",
    "statusText",
    "headers",
    "defaultData",
    "message",
  ],
  data() {
    return {
      errorCodes: {
        0:'Network Error',
        404: "The page you are looking for might have been removed had its nam changes or is temporarily unavailable.Please check URL",
        500: "Server Error. Ops There is something wrong on server. Contact Administrator",
      },
      defaultMessage: "Some Thing wrong Please contact Administrator",
    };
  },
  computed: {
    getDefaultExceptionMessage() {
      if (this.status !== 403) {
        return this.errorCodes[this.status];
      }
      if(this.status === 403 && this.defaultData['data']){
        let errorData = this.defaultData['data'];
        let html = "";
        Object.keys(errorData).forEach(key=>{
          html += `${capitalizeText(key)} : ${errorData[key]} <br/>`
        })
        return html;
      }
      return this.defaultMessage;
    },
  },
  methods:{
    handleNavigateToLogin(){
       this.$store.commit("auth/CLEAR_AUTH_DATA");
      this.$store.commit("user/CLEAR_USER_DATA");
      this.$router.push('/login');
    }
  }
};
</script>

