import axios from "axios";
import Qs from "qs";
import store from "@/store/";
import { handleAxiosError } from "./handleAxiosError";
import { getToken, getAuth, getHeaders } from "@/util/auth";

let baseURL = "";
let $axios = axios.create({
  baseURL,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "repeat" }),
});

console.log({ $axios });

function getHeader(payload) {
  let header;
  if (!payload.hasOwnProperty("headers")) {
    header = getHeaders();
  } else {
    header = payload.headers;
  }
  return header;
}


$axios.interceptors.request.use(
  (config) => {
    let auth = getAuth();
    let token = getToken();
    if (token && auth.hasOwnProperty("HTTP_HOST")) {
      baseURL = `${process.env.VUE_APP_SERVER_PROTOCOL}://${auth.HTTP_HOST}`;
      config.url = baseURL + config.url;
    }
    if (token && auth.hasOwnProperty("HTTP_HOST")) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    console.log({ request_error: error });
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log({ error });
    if (error.response) {
      if (error.response.status !== 400) {
        let errors = handleAxiosError(error);
        let httpException = {
          isHttpException: true,
          httpExceptionDetail: errors,
        };
        console.log({ httpExceptionError: errors });
        store.dispatch("setHttpException", httpException);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

function get(payload) {
  let header = getHeader(payload);
  return new Promise((resolve, reject) => {
    $axios({
      method: "get",
      url: payload.url,
      headers: header,
      params: payload.params,
      paramsSerializer: (params) =>
        Qs.stringify(params, { arrayFormat: "repeat" }),
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function post(payload) {
  let header = getHeader(payload);
  return new Promise((resolve, reject) => {
    $axios({
      method: "post",
      url: payload.url,
      headers: header,
      data: payload.data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function remove(payload) {
  let header = getHeader(payload);
  return new Promise((resolve, reject) => {
    $axios({
      method: "delete",
      url: payload.url,
      headers: header,
      data: payload.data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function patch(payload) {
  let header = getHeader(payload);
  return new Promise((resolve, reject) => {
    $axios({
      method: "patch",
      url: payload.url,
      headers: header,
      data: payload.data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
async function put(payload) {
  let header = getHeader(payload);
  return new Promise((resolve, reject) => {
    $axios({
      method: "put",
      url: payload.url,
      headers: header,
      data: payload.data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function putOrPatch(payload) {
  if (!["put", "patch"].includes(payload.method)) {
    console.error(
      method +
        ' is Not Allowed. Please Check  the request method. Must be either "post" or "patch" '
    );
  }
  let header = getHeader(payload);

  return new Promise((resolve, reject) => {
    $axios({
      method: payload.method,
      url: payload.url,
      headers: header,
      data: payload.data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  get,
  post,
  remove,
  patch,
  put,
  putOrPatch,
};
