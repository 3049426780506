import _ from "lodash";
import moment from "moment";
function resolve(path, obj) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
}

export function getExportJson({ columns, data }) {
  if (data && Array.isArray(data)) {
    return data.map((d) => {
      let obj = {};
      for (const col of columns) {
        if (_.isObject(col)) {
          let resolved = resolve(col.column_value, d);
          if (typeof resolved == "string") {
            resolved = resolved.replaceAll("#", "");
          }
          if (isValueOfDateType(resolved)) {
            resolved = moment(resolved).format("MMMM Do YYYY, h:mm:ss a");
          }
          obj[col.column_name] = resolved;
        } else {
          obj[col] = d[col];
        }
      }
      return obj;
    });
  }
}

export function isValueOfDateType(val) {
  if (!val) {
    val = "";
  }
  var formats = [moment.ISO_8601];
  return moment(val, formats, true).isValid();
}
