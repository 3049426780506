export const invoiceRoutes = [
  {
    path: "/subscription-invoice",
    component: () => import("@/layout/"),
    children: [
      {
        path: "/",
        name: "subscription-invoice-dashboard",
        component: () => import("../pages/dashboard.vue"),
        meta: {
          title: "Invoice Dashboard",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "all",
        name: "subscription-invoice-list",
        component: () => import("../pages/list.vue"),
        meta: {
          title: "Invoice List",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "detail/:subscription_invoice_uuid",
        name: "subscription-invoice-detail",
        component: () => import("../pages/detail.vue"),
        props:true,
        meta: {
          title: "Invoice List",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "payment-list",
        name: "subscription-invoice-payment-list",
        component: () => import("../pages/paymentlist.vue"),
        meta: {
          title: "Invoice Payment List",
          icon: "menu",
          noCache: true,
        },
      },
    ],
  },
];
