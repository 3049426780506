export const subscriptionRoutes = [
  {
    path: "/subscriptions",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/",
        name: "subscription-dashboard",
        component: () => import("../pages/dashboard.vue"),
        meta: {
          title: "Subscription Dashboard",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "all",
        name: "subscription-list",
        component: () => import("../pages/list.vue"),
        meta: {
          title: "Subscription List",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "tenant/:tenant_uuid/subscription/create",
        name: "subscription-create",
        component: () => import("../pages/create.vue"),
        props:true,
        meta: {
          title: "Subscription Create",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "tenant/:tenant_uuid/subscription/upgrade",
        name: "subscription-upgrade",
        component: () => import("../pages/create.vue"),
        props:true,
        meta: {
          title: "Subscription Upgrade",
          icon: "menu",
          noCache: true,
        },
      },
      {
        path: "detail/:subscription_uuid",
        name: "subscription-detail",
        component: () => import("../pages/detail.vue"),
        props:true,
        meta: {
          title: "Subscription Detail",
          icon: "menu",
          noCache: true,
        },
      },
    ],
  },
];
