export default {
  data() {
    return {
      infoIconStyle: {
        "font-size": ".70rem",
        color: "green",
      },
    };
  },
  computed: {
    elCardBodyStyle() {
      return {
        padding: this.isMobile ? "8px" : "16px",
      };
    },
  },
};
