<template>
  <div id="app">
    <div
      v-if="!$store.state.app.httpException.isHttpException"
      id="no-exception"
    >
      <router-view />
    </div>
    <div v-else id="exception">
      <httpExceptions
        :defaultData="$store.state.app.httpException.httpExceptionDetail"
        :message="$store.state.app.httpException.httpExceptionDetail.statusText"
        :status="$store.state.app.httpException.httpExceptionDetail.status"
      >
      </httpExceptions>
    </div>
  </div>
</template>
<script>
import "@/assets/lib/bootstrap/bootstrap.css";
import httpExceptions from '@/http/exceptions/';
export default {
  name: "App",
  components:{
    httpExceptions
  },
  watch: {
    $route() {
      this.$store.state.app.httpException.isHttpException = false;
    },
  },
};
</script>

