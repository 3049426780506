export default {
  name: "AskConfirmMixin",
  methods: {
    async askConfirmation(
      message,
    ) {
     
      return await this.$confirm(
        `<h4 class="text-danger">Confirm</h4>
        <div class="text-left">
        ${message}
        </div>`,
        {
          confirmButtonText: "Proceed",
          dangerouslyUseHTMLString: true,
          cancelButtonText: "Cancel",
          cancelButtonClass: "el-button--danger is-plain",
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type:'warning'
        }
      )
        .then(async () => {
          return true;
          
        })
        .catch((error) => {
          return false;
        });
    },
  },
};
