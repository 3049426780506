import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faFileInvoice,
  faUserLock,
  faMoneyCheckAlt,
  faWeight,
  faSlidersH,
  faFileInvoiceDollar,
  faArchway,
  faTruck,
  faArrowRight,
  faRedoAlt,
  faList,
  faPrint,
  faUniversity,
  faAddressBook,
  faPhoneSquareAlt,
  faUserCircle,
  faLuggageCart,
  faShoppingCart,
  faSignOutAlt,
  faHome,
  faUndoAlt,
  faWindowRestore,
  faReceipt,
  faNetworkWired,
  faIdCard,
  faImage,
  faMapMarkerAlt,
  faAddressCard,
  faUtensils,
  faUserCheck,
  faIdCardAlt,
  faBoxOpen,
  faHandHoldingUsd,
  faUsersCog,
  faChartLine,
  faShoppingBasket,
  faWallet,
  faUser,
  faStore,
  faUserTie,
  faChartBar,
  faBookReader,
  faTools,
  faTachometerAlt,
  faStoreAlt,
  faPowerOff,
  faCheck,
  faUserPlus,
  faPlus,
  faEllipsisV,
  faEllipsisH,
  faCircle,
  faEyeSlash,
  faMale,
  faFemale,
  faGenderless,
  faFileCsv,
  faCog,
  faCogs,
  faEye,
  faCartPlus,
  faMoneyCheck,
  faCheckCircle,
  faTimesCircle,
  faMinus,
  faLock,
  faLockOpen,
  faSearch,
  faFilter,
  faTasks,
  faTags,
  faCirclePlus,
  faMoneyBill,
  faXmark
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faXmark,
  faMoneyBill,
  faTasks,
  faBuilding,
  faFileInvoice,
  faUserLock,
  faMoneyCheckAlt,
  faWeight,
  faSlidersH,
  faFileInvoiceDollar,
  faArchway,
  faTruck,
  faArrowRight,
  faRedoAlt,
  faList,
  faPrint,
  faUniversity,
  faAddressBook,
  faPhoneSquareAlt,
  faUserCircle,
  faLuggageCart,
  faShoppingCart,
  faSignOutAlt,
  faHome,
  faUndoAlt,
  faWindowRestore,
  faReceipt,
  faNetworkWired,
  faIdCard,
  faImage,
  faMapMarkerAlt,
  faAddressCard,
  faUtensils,
  faUserCheck,
  faIdCardAlt,
  faBoxOpen,
  faHandHoldingUsd,
  faUsersCog,
  faChartLine,
  faShoppingBasket,
  faWallet,
  faUser,
  faStore,
  faUserTie,
  faChartBar,
  faBookReader,
  faTools,
  faTachometerAlt,
  faStoreAlt,
  faPowerOff,
  faCheck,
  faUserPlus,
  faPlus,
  faEllipsisV,
  faEllipsisH,
  faPlus,
  faCircle,
  faEyeSlash,
  faEye,
  faMale,
  faFemale,
  faGenderless,
  faCog,
  faCogs,
  faCartPlus,
  faMoneyCheck,
  faCheckCircle,
  faTimesCircle,
  faMinus,
  faFileCsv,
  faLockOpen,
  faLock,
  faSearch,
  faFilter,
  faTags,
  faCirclePlus
);
