export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 450;
    },
    ulPadding() {
      return this.isMobile ? { padding: 0 } : {};
    },
    inputSize() {
      return this.isMobile ? "mini" : "small";
    },
    showIconInErrorMessage() {
      return !this.isMobile;
    },
    isMainLabelFixed() {
      return this.isMobile;
    },
    fixedActionPosition() {
      return this.isMobile ? false : "right";
    },
    respDialogWidth() {
      return this.isMobile ? "80" : "60";
    },
    respLabelPosition() {
      return this.isMobile ? "top" : "left";
    },
  },
  created() {
    window.addEventListener("resize", this.viewPortResize);
  },
  methods: {
    viewPortResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
