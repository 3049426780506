import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { getToken } from "@/util/auth";

export default {
  namespaced: true,
  state: {
    token: getToken(),
    auth_data: JSON.parse(localStorage.getItem("saas_admin_auth")) || null,
  },
  actions,
  getters,
  mutations,
};
