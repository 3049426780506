const constants = {
  business: {
    COMPANY: {
      ESTIMATED_ANNUAL_TURNOVER_OPTIONS: [
        {
          label: 'Nepal',
          value: [
            {
              label: 'Not Open Yet',
              value: 'not_open_yet',
            },
            {
              label: '1 Lakh -  10Lakh',
              value: 'npr_1L_to_10L',
            },
            {
              label: '10 Lakh - 20 Lakh',
              value: 'npr_10L_to_20L',
            },
            {
              label: '20 Lakh  - 50 Lakh',
              value: 'npr_20L_to_50L',
            },
            {
              label: '50 Lakh - 1 Crore',
              value: 'npr_50L_to_1C',
            },
            {
              label: '1 Crore - 3 Crore',
              value: 'npr_1C_to_3C',
            },
            {
              label: '3 Crore - 5 Crore',
              value: 'npr_3C_to_5C',
            },
            {
              label: '5 Crore - 10 Crore',
              value: 'npr_5C_to_10C',
            },
            {
              label: '10 Crore & Above',
              value: 'npr_10_and_above',
            },
          ],
        },
        {
          label: 'Global',
          value: [
            {
              label: 'Not Open Yet',
              value: 'not_open_yet',
            },
            {
              label: '$1k - $100k',
              value: 'usd_1k_to_100k',
            },
            {
              label: '$100k - $300k',
              value: 'usd_100k_to_300k',
            },
            {
              label: '$300k - $600k',
              value: 'usd_300k_to_600k',
            },
            {
              label: '$600k - $1M',
              value: 'usd_600k_to_1M',
            },
            {
              label: '$1M - $10M',
              value: 'usd_1M_to_10M',
            },
            {
              label: '$10M - $20M',
              value: 'usd_10M_to_20M',
            },
            {
              label: '$20M - $50M',
              value: 'usd_20M_to_50M',
            },
            {
              label: '$50M & Above',
              value: 'usd_50M_and_above',
            },
          ],
        },
      ],
      BUSINESS_TYPE_OPTIONS: {
        'Atrists Photographers Creative':
          'Artists, Photographers & Creative Types',
        'Beauty Salon Spa and Aesthetics': 'Beauty Salon, Spa & Aesthetics',
        'Consultants and Professionals': 'Consultants & Professionals',
        'Financial Services': 'Financial Services',
        'Product Provider': 'General:I make or buy or sell a Product',
        'Service Provider': 'General:I Provide a Service',
        'Non-profits organizations': 'Non-profits Organizations & Groups',
        'Real Estate Construction  Home and Improvement':
          'Real Estate ,Construction & Home Improvement',
        'Retailers and Resellers': 'Retailers, Resellers & Sales',
        'Web Tech Media': 'Web, Tech & Media',
        Other: 'Other',
      },
      NO_OF_STORES_YOU_HAVE_OPTIONS: [
        {
          label: 'Only One Store',
          value: 'Single outlet',
        },
        {
          label: '2-5 outlets',
          value: '2-5 outlets',
        },
        {
          label: '5-10 outlets',
          value: '5-10 outlets',
        },
        {
          label: '10+ outlets',
          value: '10+ outlets',
        },
      ],
    },
  },
  tenant: {
    STATUS_OPTIONS: [
      {
        label: 'Draft',
        value: 'Draft',
      },
      {
        label: 'Pending',
        value: 'Pending',
      },
      {
        label: 'Approved',
        value: 'Approved',
      },
      {
        label: 'Declined',
        value: 'Declined',
      },
      {
        label: 'Black Listed',
        value: 'Black Listed',
      },
    ],
    NO_OF_EMPLOYEES_YOU_HAVE_OPTIONS: [
      {
        label: '1 - 10',
        value: '1-10',
      },
      {
        label: '10 - 20',
        value: '10-20',
      },
      {
        label: '20 - 50',
        value: '20-50',
      },
      {
        label: '50 - 100',
        value: '50-100',
      },
      {
        label: '100+',
        value: '100+',
      },
    ],
    NO_OF_BUSINESSES_YOU_HAVE_OPTIONS: [
      {
        label: 'Only One',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
      {
        label: '5',
        value: 5,
      },
      {
        label: '6',
        value: 6,
      },
      {
        label: '7',
        value: 7,
      },
      {
        label: '8',
        value: 8,
      },
      {
        label: '9',
        value: 9,
      },
      {
        label: '10 plus',
        value: 10,
      },
    ],
    REGION_OPTIONS: [
      {
        label: 'Nepal',
        value: 'Nepal',
      },
      {
        label: 'Global',
        value: 'Global',
      },
    ],
  },
  subscription: {
    RECURRING_TYPE_OPTIONS: [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Yearly',
        value: 'yearly',
      },
    ],
  },
  users: {
    USER_TYPE_OPTIONS: [
      {
        label: 'Individual',
        value: 'Individual',
      },
      {
        label: 'Company',
        value: 'Company',
      },
    ],
  },
  core: {
    GENDER_OPTIONS: [
      {
        label: 'Male',
        value: 'Male',
      },
      {
        label: 'Female',
        value: 'Female',
      },
      {
        label: 'Prefer no to say',
        value: 'Prefer no to say',
      },
    ],
    TITLE_OPTIONS: [
      {
        label: 'Mr',
        value: 'Mr',
      },
      {
        label: 'Miss',
        value: 'Miss',
      },
      {
        label: 'Mrs',
        value: 'Mrs',
      },
      {
        label: 'Ms',
        value: 'Ms',
      },
      {
        label: 'Dr',
        value: 'Dr',
      },
      {
        label: 'Sir',
        value: 'Sir',
      },
    ],
    MARITAL_STATUS_OPTIONS: [
      {
        label: 'Married',
        value: 'Married',
      },
      {
        label: 'Unmarried',
        value: 'Unmarried',
      },
      {
        label: 'Divorced',
        value: 'Divorced',
      },
      {
        label: 'Prefer no to say',
        value: 'Prefer no to say',
      },
    ],
    INDUSTRIES_OPTIONS: [
      {
        label: 'Apparel & Accessories',
        value: 'Apparel & Accessories',
      },
      {
        label: 'Automotive',
        value: 'Automotive',
      },
      {
        label: 'Banking',
        value: 'Banking',
      },
      {
        label: 'Biotechnology',
        value: 'Biotechnology',
      },
      {
        label: 'Building Materials & Equipment',
        value: 'Building Materials & Equipment',
      },
      {
        label: 'Chemical',
        value: 'Chemical',
      },
      {
        label: 'Computer',
        value: 'Computer',
      },
      {
        label: 'Design',
        value: 'Design',
      },
      {
        label: 'Education',
        value: 'Education',
      },
      {
        label: 'Electronics',
        value: 'Electronics',
      },
      {
        label: 'Energy',
        value: 'Energy',
      },
      {
        label: 'Entertainment & Leisure',
        value: 'Entertainment & Leisure',
      },
      {
        label: 'Finance',
        value: 'Finance',
      },
      {
        label: 'Food & Beverage',
        value: 'Food & Beverage',
      },
      {
        label: 'Grocery',
        value: 'Grocery',
      },
      {
        label: 'Health & Fitness',
        value: 'Health & Fitness',
      },
      {
        label: 'Healthcare',
        value: 'Healthcare',
      },
      {
        label: 'It & Software',
        value: 'It & Software',
      },
      {
        label: 'Insurance',
        value: 'Insurance',
      },
      {
        label: 'Legal',
        value: 'Legal',
      },
      {
        label: 'Lifestyle',
        value: 'Lifestyle',
      },
      {
        label: 'Manufacturing',
        value: 'Manufacturing',
      },
      {
        label: 'Marketing And Advertisingagriculture',
        value: 'Marketing And Advertisingagriculture',
      },
      {
        label: 'Music',
        value: 'Music',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Personal Development',
        value: 'Personal Development',
      },
      {
        label: 'Photography',
        value: 'Photography',
      },
      {
        label: 'Press And Printing',
        value: 'Press And Printing',
      },
      {
        label: 'Publishing',
        value: 'Publishing',
      },
      {
        label: 'Real Estate',
        value: 'Real Estate',
      },
      {
        label: 'Service',
        value: 'Service',
      },
      {
        label: 'Sports',
        value: 'Sports',
      },
      {
        label: 'Teaching & Academic',
        value: 'Teaching & Academic',
      },
      {
        label: 'Technology',
        value: 'Technology',
      },
      {
        label: 'Telecommunications',
        value: 'Telecommunications',
      },
      {
        label: 'Television',
        value: 'Television',
      },
      {
        label: 'Transportation',
        value: 'Transportation',
      },
      {
        label: 'Venture Capital',
        value: 'Venture Capital',
      },
    ],
    CURRENCY_OPTIONS: [
      {
        label: 'NPR',
        value: 'NPR',
      },
    ],
    TIMEZONE_OPTIONS: [
      {
        label: 'Asia/Kathmandu',
        value: 'Asia/Kathmandu',
      },
    ],
    COUNTRY_OPTIONS: [
      {
        label: 'Nepal',
        value: 'Nepal',
      },
    ],
    COMPANY_TYPE_OPTIONS: [
      {
        label: 'Sole Proprietorship',
        value: 'Sole Proprietorship',
      },
      {
        label: 'Partnership',
        value: 'Partnership',
      },
      {
        label: 'Corporation',
        value: 'Corporation',
      },
      {
        label: 'Non-Profit',
        value: 'Non-Profit',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  payment: {
    PaymentMethod: {
      PAYMENT_OPTIONS: [
        {
          label: 'Cash',
          value: 'Cash',
        },
        {
          label: 'Card',
          value: 'Card',
        },
        {
          label: 'Bank Transfer',
          value: 'Bank Transfer',
        },
        {
          label: 'Online Payment',
          value: 'Online Payment',
        },
        {
          label: 'Cheque',
          value: 'Cheque',
        },
        {
          label: 'Coupon',
          value: 'Coupon',
        },
        {
          label: 'Voucher',
          value: 'Voucher',
        },
        {
          label: 'Other Payment',
          value: 'Other Payment',
        },
      ],
    },
  },
}
export default constants
