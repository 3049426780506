const formRules = {
  required: (key,trigger= 'blur') => {
    return {
      required: true,
      trigger,
      message: trigger === 'blur' ? `${key} is required.` : `${key} must be selected.`,
    }
  },
  email: () => {
    return {
      type: "email",
      trigger: 'blur',
      message:'Enter a valid E-mail Address'
    }
  }
}
export default formRules;
