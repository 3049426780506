import Vue from "vue";
import viewPortMixin from "@/mixins/ViewportMixin";
import userMixin from "@/mixins/UserMixin";
import SSValidationMixin from '@/mixins/ServerSideValidationMixin';
import TableWidthMixin from '@/mixins/tableWidthMixin';
import styleMixin from "@/mixins/styleMixin";
import  deleteObjectMixin  from "@/mixins/DeleteObject";
import AskConfirmation from "@/mixins/AskConfirmation";
import {get_error_schema} from '@/util/'
// Global Components
Vue.mixin({
  methods:{
      get_error_schema
  },
  computed: {
    is_local_development() {
      return (
        process.env.NODE_ENV === "development" && process.env.VUE_APP_DEBUG
      );
    },
    appEnv() {
      let hostName = process.env.VUE_APP_HOST_NAME;
      if (typeof hostName === "string") {
        let env = hostName.split(".")[0];
        if (env === "demo-api") {
          return "demo";
        } else if (env === "staging-api") {
          return "staging";
        }
      }
      return null;
    },

  },
});

Vue.mixin(viewPortMixin);
Vue.mixin(userMixin);
Vue.mixin(TableWidthMixin);
Vue.mixin(SSValidationMixin);
Vue.mixin(styleMixin);
Vue.mixin(deleteObjectMixin);
Vue.mixin(AskConfirmation);

