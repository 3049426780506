<template>
  <el-row type="flex" :align="rowAlign">
    <el-col :span="labelLength">
      <div class="text-capitalize fitWidth text-break label">
        <slot name="label">{{ label }}</slot>
      </div>
    </el-col>
    <el-col :span="1">
      <div class="font-weight-bolder text-break">
        <span v-if="!noSeperator">:</span>
      </div>
    </el-col>
    <el-col :span="inputLength">
      <div class="font-weight-light text-break" id="relative">
        <slot name="value">{{ value }}</slot>
        <slot name="error" v-if="error">
          {{ error }}
        </slot>
        <div id="help_text" v-if="!error">
          <slot name="help_text">
            {{ help_text }}
          </slot>
        </div>
      </div>
    </el-col>
    <el-col :push="1" :span="3" v-if="icon">
      <div class="font-weight-light align-middle text-break">
        <slot name="icon">
          {{ icon }}
        </slot>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "DetailTableRowItem",
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    noSeperator: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: Boolean,
      required: false,
    },
    error: {
      type: [Boolean, String, Object],
      required: false,
      default: false,
    },
    oneThird: {
      type: Boolean,
      required: false,
      default: false,
    },
    popOver: {
      type: Boolean,
      required: false,
      default: false,
    },
    help_text: {
      type: String,
      required: false,
    },
    topAlign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inputLength() {
      if (this.popOver) {
        return 5;
      }
      return this.oneThird ? 13 : this.icon ? 13 : 16;
    },
    rowAlign() {
      if (this.topAlign) {
        return "top";
      }
      return this.error ? "top" : "middle";
    },
    labelLength() {
      if (this.popOver) {
        return 18;
      }
      return this.oneThird ? 10 : 7;
    },
  },
};
</script>

<style scoped>
th.fitWidth {
  width: 1px;
  white-space: nowrap;
}
.text-break {
  word-break: break-all;
}

.el-row .el-row--flex {
  margin: 10px 0;
}
.label {
  font-weight: 500 !important;
}

#relative {
  position: relative;
}

#help_text {
  position: absolute;
  bottom: -15px;
  font-size: 0.7rem;
  color: grey;
}
</style>
