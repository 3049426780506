<template>
  <div class="text-center">
    <el-tooltip
      class="item"
      effect="dark"
      content="Refresh page"
      placement="bottom"
    >
      <el-button
        size="small"
        type="primary"
        class="mr-2"
        @click="handleClick"
        icon="el-icon-refresh-right"
      >
        {{ retry ? "Try Again" : "Refresh page" }}
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    retry: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.retry) {
        this.$emit("retry");
        return;
      }
      this.$router.go(0);
    },
  },
};
</script>

<style>
</style>