import { Message } from "element-ui";
export default function displayMessage(type, payload) {
  let message = payload;
  if (typeof message !== "string") {
    message = payload.message;
  }

  Message({
    type,
    message,
    dangerouslyUseHTMLString: true,
  });
}
