<template>
  <div id="base-dialog">
    <el-dialog
      :close-on-click-modal="closeOnClickDialog"
      :visible.sync="show"
      :width="compDialogWidth"
      :fullscreen="fullscreen"
      @close="updateShowDialog"
      :lock-scroll="lockScroll"
      :show-close="!loading"
      top="5vh"
      center
      append-to-body
    >
      <!-- :show-close="!hideCloseIcon" -->
      <template slot="title">
        <h5>
          {{ getDialogTitle }}
        </h5>
      </template>
      <div class="dialog-body">
        <slot name="body"></slot>
      </div>
      <slot name="dialog"></slot>
      <span slot="footer" class="dialog-footer" name="el-zoom-in-bottom">
        <el-button
          v-if="!hideCancelButton"
          type="danger"
          plain
          @click="updateShowDialog"
          :disabled="loading"
          :size="isMobile ? 'mini':'small'"
          >Cancel</el-button
        >
        <!-- :disabled="disableCancelButton" -->
        <slot name="footer"></slot>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "base-dialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
      required: false,
    },
    closeOnClickDialog: {
      type: Boolean,
      required: false,
    },
    hideCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogWidth: {
      type: [String, Number],
      required: false,
    },
    disableCancelButton: {
      type: Boolean,
      default: false,
    },
    hideCloseIcon: {
      type: Boolean,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: this.showDialog,
    };
  },
  watch: {
    showDialog(isVisible) {
      this.show = isVisible;
    },
    show(isVisible) {
      if (!isVisible) {
        this.$emit("update:showDialog", false);
      }
    },
  },
  computed: {
    getDialogTitle() {
      return this.title;
    },
    compDialogWidth() {
      if (this.isMobile) {
        return "95%";
      }
      return this.dialogWidth ? this.dialogWidth + "%" : "70%";
    },
    inputSize() {
      return this.isMobile ? "mini" : "";
    },
  },
  methods: {
    updateShowDialog() {
      this.$emit("update:showDialog", false);
    },
  },
};
</script>

<style>
.dialog-body > .el-card{
  max-height:70vh !important;
  overflow:auto;
}
</style>
