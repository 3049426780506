<template>
  <div class="lp-tag d-inline-block outline" v-bind:style="getTag.style">
    <el-tooltip
      class=""
      effect="dark"
      :content="detail ? detail : getTag.label"
      placement="top-start"
    >
      <span class="tag-icon" v-if="getTag.icon">
        <font-awesome-icon :icon="getTag.icon" />
      </span>
      <span>
        {{ getTag.label }}
      </span>
    </el-tooltip>
  </div>
</template>

<script>
const TAGS = {
  Processing: {
    bgColor: "#eef7f7",
    textColor: "#6bb9b9",
    elementType: "tag",
    label: "Processing",
    borderColor: "#deeeee",
  },
  Draft: {
    bgColor: "#fdf6ec",
    textColor: "#e6a23c",
    icon: "file-csv",
    elementType: "tag",
    label: "Draft",
    borderColor: "#faecd8",
  },
  Open: {
    bgColor: "#f4e9f4",
    textColor: "#EE82EE",
    icon: "file-csv",
    elementType: "tag",
    label: "Open",
    borderColor: "#f4ddf4",
  },
  Hold: {
    bgColor: "#F4F3ED",
    textColor: "#d7ca97",
    icon: "file-csv",
    elementType: "tag",
    label: "Hold",
    borderColor: "#f4f1e2",
  },
  Sent: {
    bgColor: "#ecf5ff",
    textColor: "#409eff",
    elementType: "tag",
    label: "Sent",
    borderColor: "#d9ecff",
  },
  Closed: {
    bgColor: "#fef0f0",
    textColor: "#f56c6c",
    elementType: "tag",
    label: "Closed",
    borderColor: "#fde2e2",
  },
  Approved: {
    bgColor: "#f4f4f5",
    textColor: "#909399",
    elementType: "tag",
    label: "Approved",
    borderColor: "#e9e9eb",
  },
  Dispatched: {
    bgColor: "#ebf9eb",
    textColor: "#32CD32",
    elementType: "tag",
    label: "Dispatched",
    borderColor: "#e1f3d8",
  },
  Cancelled: {
    bgColor: "#f9dbe1",
    textColor: "#FA8072",
    elementType: "tag",
    label: "Cancelled",
    borderColor: "#f7c9c3",
  },
  "N/A": {
    bgColor: "#f9dbe1",
    textColor: "#FA8072",
    elementType: "tag",
    label: "N/A",
    borderColor: "#f7c9c3",
  },
  Void: {
    bgColor: "#f9dbe1",
    textColor: "#FA8072",
    elementType: "tag",
    label: "Void",
    borderColor: "#f7c9c3",
  },
  Completed: {
    bgColor: "#f0f9eb",
    textColor: "#67c23a",
    icon: "file-csv",
    elementType: "tag",
    label: "Completed",
    borderColor: "#e1f3d8",
  },
  Confirmed: {
    bgColor: "#f0f9eb",
    textColor: "#67c23a",
    icon: "file-csv",
    elementType: "tag",
    label: "Confirmed",
    borderColor: "#e1f3d8",
  },
  Paid: {
    bgColor: "#ebf9eb",
    textColor: "#32CD32",
    elementType: "tag",
    label: "Paid",
    borderColor: "#e1f3d8",
  },
  "Not Paid": {
    bgColor: "#fef0f0",
    textColor: "#f56c6c",
    elementType: "tag",
    label: "Not Paid",
    borderColor: "#fde2e2",
  },
  "Partially Paid": {
    bgColor: "#ecf5ff",
    textColor: "#409eff",
    elementType: "tag",
    label: "Partially Paid",
    borderColor: "#d9ecff",
  },
  Shipped: {
    bgColor: "#ebf9eb",
    textColor: "#32CD32",
    elementType: "tag",
    label: "Shipped",
    borderColor: "#e1f3d8",
  },
  Delivered: {
    bgColor: "#ebf9eb",
    textColor: "#32CD32",
    elementType: "tag",
    label: "Delivered",
    borderColor: "#e1f3d8",
  },
  "Not Shipped": {
    bgColor: "#fef0f0",
    textColor: "#f56c6c",
    elementType: "tag",
    label: "Not Shipped",
    borderColor: "#fde2e2",
  },
  "Partially Shipped": {
    bgColor: "#ecf5ff",
    textColor: "#409eff",
    elementType: "tag",
    label: "Partially Shipped",
    borderColor: "#d9ecff",
  },
  "Partially Fulfilled": {
    bgColor: "#ecf5ff",
    textColor: "#409eff",
    elementType: "tag",
    label: "Partially Fulfilled",
    borderColor: "#d9ecff",
  },
  Unfulfilled: {
    bgColor: "#fef0f0",
    textColor: "#f56c6c",
    elementType: "tag",
    label: "Unfulfilled",
    borderColor: "#fde2e2",
  },
  Published: {
    bgColor: "#ebf9eb",
    textColor: "#32CD32",
    elementType: "tag",
    label: "Published",
    borderColor: "#e1f3d8",
  },
  "Out Of Stock": {
    bgColor: "#fef0f0",
    textColor: "#f56c6c",
    elementType: "tag",
    label: "Out Of Stock",
    borderColor: "#fde2e2",
  },
  Low: {
    bgColor: "#80D8FB",
    textColor: "#E7FAED",
    elementType: "tag",
    label: "Low",
    borderColor: "#fde2e2",
  },
  Medium: {
    bgColor: "#98C14A",
    textColor: "#ffffff",
    elementType: "tag",
    label: "Medium",
    borderColor: "#fde2e2",
  },
  High: {
    bgColor: "#FA5A59",
    textColor: "#ffffff",
    elementType: "tag",
    label: "High",
    borderColor: "#fde2e2",
  },
  Urgent: {
    bgColor: "#DD3B3C",
    textColor: "#ffffff",
    elementType: "tag",
    label: "Urgent",
    borderColor: "#fde2e2",
  },
  New: {
    bgColor: "#42253B",
    textColor: "#E7FAED",
    elementType: "tag",
    label: "New",
    borderColor: "#fde2e2",
  },
  Ready: {
    bgColor: "#192A51",
    textColor: "#ffffff",
    elementType: "tag",
    label: "Ready",
    borderColor: "#fde2e2",
  },
  "In Review": {
    bgColor: "#F7CB15",
    textColor: "#ffffff",
    elementType: "tag",
    label: "In Review",
    borderColor: "#fde2e2",
  },
  "In Progress": {
    bgColor: "#5185FB",
    textColor: "#ffffff",
    elementType: "tag",
    label: "In Progress",
    borderColor: "#fde2e2",
  },
  "On Hold": {
    bgColor: "#F44B2A",
    textColor: "#E7FAED",
    elementType: "tag",
    label: "On Hold",
    borderColor: "#fde2e2",
  },
};

export default {
  name: "tagIndex",
  props: {
    value: {
      type: [String],
      required: false,
    },
    detail: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    getTag() {
      const tag = TAGS[this.value];
      return {
        label: tag ? tag["label"] || this.value : this.value,
        style: {
          backgroundColor: tag ? tag["bgColor"] || "#f4f4f5" : "#f4f4f5",
          color: tag ? tag["textColor"] || "#909399" : "#909399",
          borderColor: tag ? tag["borderColor"] || "#e9e9eb" : "#e9e9eb",
        },
        icon: tag ? tag["icon"] || "" : "",
        elementType: tag ? tag["elementType"] || "" : "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.lp-tag {
  display: flex;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #023129;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  .tag-icon {
    margin-right: 15px;
  }
}
</style>
