import  Cookies  from "js-cookie/src/js.cookie";

const TokenKey = "saas_admin_token";
const AuthKey = "saas_admin_auth";

export function getToken() {
  return Cookies.get(TokenKey);
}
export function getAuth() {
  return Cookies.getJSON(AuthKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: new Date(new Date().getTime() + 120 * 60 * 1000),
  });
}

export function setAuth(auth) {
  return Cookies.set(AuthKey, auth, {
    expires: new Date(new Date().getTime() + 120 * 60 * 1000),
  });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeAuth() {
  return Cookies.remove(AuthKey);
}

export function getHeaders() {
  const token = getToken();
  var headers = {
    Accept: "application/json",
  };
  if (token != null) {
    headers["Authorization"] = `Token ${token}`;
  }
  return headers;
}

export function clearAuthCookies() {
  Cookies.remove(TokenKey);
  Cookies.remove(AuthKey);
}
