<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Download as csv"
    placement="bottom-end"
  >
    <div v-if="!hideIcon" id="csvSpinnerTarget">
      <font-awesome-icon
        icon="file-csv"
        @click="csvExport"
        size="2x"
        class="cursor-pointer"
      />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: "ExportToCSV",
  props: ["data", "file_name", "hideIcon"],
  methods: {
    csvExport() {
      const target = document.querySelector("#csvSpinnerTarget");
      const loading = this.$loading({
        target,
        spinner: "el-icon-loading",
      });
      const arrData = this.data;
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map((item) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", (this.file_name || "data") + ".csv");
      link.click();
      loading.close();
    },
  },
};
</script>
