import Vuex from "vuex";
import Vue from "vue";
import auth from "../auth/store/";
import user from './user/'
import app from './app';
import rolesAndPermission from './roleAndPermission';

Vue.use(Vuex);
const store =  new Vuex.Store({
  modules: {
    auth,
    user,
    app,
    rolesAndPermission,
  },
});
export default store;