export function handleAxiosError(axiosError) {
  let errorDetail = {
    type: "",
    data: "",
    status: "",
    statusText: "",
    headers: "",
    default: "",
  };
  if (axiosError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    errorDetail.type = "response";
    errorDetail.data = axiosError.response.data;
    errorDetail.status = axiosError.response.status;
    errorDetail.statusText = axiosError.response.statusText;
    errorDetail.headers = axiosError.response.headers;
    errorDetail.default = axiosError.response;
  } else if (axiosError.request) {
    // The request was made but no response was received
    // `axiosError.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    errorDetail.type = "request";
    errorDetail.data = axiosError.request;
    errorDetail.status = axiosError.request;
    errorDetail.headers = axiosError.request;
    errorDetail.default = axiosError.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    errorDetail.type = "setup";
    errorDetail.data = axiosError.message;
    errorDetail.status = axiosError;
    errorDetail.headers = axiosError;
    errorDetail.default = axiosError;
  }
  return errorDetail;
}
