import { Message } from "element-ui";

export async function set_user_data({ commit }) {
  try {
    let response = await global.$http.get({
      url: "/api/v1/user/",
    });
    if (!response) {
      throw new Error("Unable to fetch user information at the moment.");
    }
    if (response && response.data) {
      let user_data = response.data;
      commit("SET_USER_DATA", user_data);
      commit("SET_USER_PERMISSIONS", user_data.permissions, { root: true });
      return user_data;
    }
  } catch (error) {
    if (error && error.message) {
      Message.error({
        message: error.message,
      });
    }
    throw error;
  }
}
