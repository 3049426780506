import { ip } from '@/roles and permissions/index.js'
import _ from 'lodash';
const rolesAndPermissionStore = {
  state: {
    permissions: JSON.parse(localStorage.getItem('saas_admin_user_permissions')) || null,
    access: {},
  },
  getters: {
    userPermissions: (state) => state.permissions,
    access: (state) => state.access,
  },
  mutations: {
    SET_USER_ACCESS(state) {
      if (!_.isEmpty(state.access) ) {
        console.log("user access already set.");
        return;
      }
      console.log("setting user access");
      const data = {
        // App settings / store
        store: {
          store: {
            create: ip('store.add_store'),
            view: ip('store.view_store'),
            update: ip('store.change_store'),
            delete: ip('store.delete_store'),
          },
          contactPerson: {
            create: ip('store.add_storecontactperson'),
            view: ip('store.view_storecontactperson'),
            update: ip('store.change_storecontactperson'),
            delete: ip('store.delete_storecontactperson'),
          },
        },
        // catalogue
        catalogue: {
          category: {
            create: ip('catalogue.add_productcategory'),
            view: ip('catalogue.view_productcategory'),
            update: ip('catalogue.change_productcategory'),
            delete: ip('catalogue.delete_productcategory'),
          },
          brand: {
            create: ip('catalogue.add_productbrand'),
            view: ip('catalogue.view_productbrand'),
            update: ip('catalogue.change_productbrand'),
            delete: ip('catalogue.delete_productbrand'),
          },
          product: {
            create: ip('catalogue.add_product'),
            view: ip('catalogue.view_product'),
            update: ip('catalogue.change_product'),
            delete: ip('catalogue.delete_product'),
          },
          /* productVariant: {
            create: ip('catalogue.add_productvariant'),
            view: ip('catalogue.view_productvariant'),
            update: ip('catalogue.change_productvariant'),
            delete: ip('catalogue.delete_productvariant'),
          }, */
        },
        // Business/Company Profile
        business: {
          company: {
            view: ip('business.view_company'),
            update: ip('business.change_company'),
          },
          branch: {
            view: ip('business.view_companybranch'),
            update: ip('business.change_companybranch'),
          },
          contactPerson: {
            create: ip('business.add_companycontactperson'),
            view: ip('business.view_companycontactperson'),
            update: ip('business.change_companycontactperson'),
            delete: ip('business.delete_companycontactperson'),
          },
          bankAccount: {
            create: ip('business.add_companybankaccount'),
            view: ip('business.view_companybankaccount'),
            update: ip('business.change_companybankaccount'),
            delete: ip('business.delete_companybankaccount'),
          },
          estimateSetting: {
            update: ip('business.change_estimatesettings'),
            view: ip('business.view_estimatesettings'),
          },
          invoiceSetting: {
            update: ip('business.change_invoicesettings'),
            view: ip('business.view_invoicesettings'),
          },
          invoiceReturnSetting: {
            update: ip('business.change_invoicereturnsettings'),
            view: ip('business.view_invoicereturnsettings'),
          },
        },
        // user details
        users: {
          user: {
            create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.delete_user'),
          },
          userAccount: {
            create: ip('user_account.add_useraccount'),
            view: ip('user_account.view_useraccount'),
            update: ip('user_account.change_useraccount'),
            delete: ip('user_account.delete_useraccount'),
          },
          userAddress: {
             create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.change_user'),
          },
          userBankAccount: {
             create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.change_user'),
          },
          userContactDetail: {
            create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.change_user'),
          },
          userContactPerson: {
             create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.change_user'),
          },
          userProfile: {
             create: ip('users.add_user'),
            view: ip('users.view_user'),
            update: ip('users.change_user'),
            delete: ip('users.change_user'),
          },
        },
      }
      state.access = { ...data };
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.permissions = permissions
      localStorage.setItem('saas_admin_user_permissions', JSON.stringify(permissions))
      this.commit('SET_USER_ACCESS')
    },
    REMOVE_USER_ROLES_AND_PERMISSION(state) {
      state.permissions = null;
      state.access = {}
      localStorage.removeItem('saas_admin_user_permissions')
    },
  },
  actions: {},
}

export default rolesAndPermissionStore
