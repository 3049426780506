import {
  clearAuthCookies,
  setAuth,
  setToken as setTokenCookie,
} from "../../util/auth";

export function setToken(state, token) {
  state.token = token;
}
export function clearToken(state) {
  state.token = null;
}
export function SET_AUTH_DATA(state, authData) {
  state.auth_data = authData;
  setAuth(authData);
  setTokenCookie(authData.token);
  localStorage.setItem("saas_admin_auth", JSON.stringify(authData));
}

export function CLEAR_AUTH_DATA(state) {
  state.auth_data = null;
  state.token = null;
  localStorage.removeItem("saas_admin_auth");
  clearAuthCookies();
}
