<template>
  <el-alert
    @close="handleAlertClose"
    type="error"
    class="my-2"
    v-if="
      error &&
      (error.clientError ||
        error.responseError.status ||
        error.responseError.data ||
        error.requestError.status ||
        error.requestError.data)
    "
    :closable="closable"
    :show-icon="showIconInErrorMessage"
  >
    <template slot="title"
      >Please fix the following errors at {{ title }}
    </template>
    <template>
      <div v-if="error.clientError">
        <pre class="text-danger">
        {{ error.clientError }}
            </pre
        >
      </div>
      <ul
        v-if="error.responseError.status || error.responseError.data"
        :style="ulPadding"
      >
        <div v-if="error.responseError.data.length != 1">
          <li v-for="(value, key) in error.responseError.data" :key="key">
            <div
              v-if="key !== 'item_lines' && key !== 'rows' && key !== 'lines'"
            >
              <span class="key">
                {{ key ? key.replaceAll("_", " ") : key }}
                <!-- {{ key ? key.replace(/_/g, " ") : key }} -->
              </span>
              :
              <span v-if="typeof value == 'string'">
                {{ value }}
              </span>
              <span v-else-if="typeof value[0] == 'string'">
                {{ value[0] }}
              </span>
              <ul v-else>
                <li>
                  <div v-for="(value, key) in value" :key="key">
                    <span class="key" v-if="key.length > 0">
                      {{
                        key.length < 2
                          ? parseInt(key) + 1
                          : key.replaceAll("_", " ")
                      }}
                      :
                    </span>
                    <span
                      :inner-html.prop="
                        value[0] ? value[0] : handleDisplayError(value)
                      "
                    >
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <span class="key">
                {{ key ? key.replaceAll("_", " ") : key }}
                <!-- Item Lines :  -->
              </span>
              <span v-if="typeof value[0] === 'string'">
                {{ value[0] }}
              </span>
              <nested-item-line-error-display
                v-else
                :error="value"
              ></nested-item-line-error-display>
            </div>
          </li>
        </div>
        <div v-else>
          {{ error.responseError.data[0] }}
        </div>
      </ul>
      <div v-if="error.requestError.status && error.requestError.data">
        <b>{{ error.requestError.status }}</b> -
        <pre class="text-danger">
            {{ error.requestError.data }}
            </pre
        >
      </div>
    </template>
  </el-alert>
</template>

<script>
import { get_error_schema } from "@/util";
export default {
  components: {
    NestedItemLineErrorDisplay: () =>
      import("./nestedItemLineErrorDisplay.vue"),
  },
  props: {
    error: {
      type: Object,
    },
    title: {
      type: String,
      require: false,
      default: "",
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleAlertClose() {
      this.$emit("update:error", get_error_schema());
    },
    handleDisplayError(value) {
      if (typeof value == "object") {
        let keys = Object.keys(value);
        let values = Object.values(value);
        let html = "";
        for (let i = 0; i < keys.length; i++) {
          html += `<li>
          <span class="text-weight-bold key " >
                    ${keys[i].replaceAll("_", " ")}
              </span>
              : ${values[i]}<br/>
              `;
        }
        html += "</li>";
        return html;
      } else if (typeof value == "string") {
        return value;
      } else {
        return value[0];
      }
    },
  },
};
</script>

<style lang="scss">
.key {
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: bolder;
}
ul > li {
  list-style: none;
}
</style>
