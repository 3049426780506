import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import nestedErrorMessage from "@/components/ErrorMessage/nestedErrorMessage";
import Breadcrumbs from '@/components/Breadcrumbs/';
import BaseTable from '@/components/Table/'
import Tag from '@/components/Tag/'
import RefreshPage from '@/components/RefreshPage/';
import NavigateToGivenRoute from '@/components/NavigateToGivenRoute/'
import DetailListWrapper from '@/components/DetailList/DetailListWrapper'
import DetailListItem from '@/components/DetailList/DetailListRowItem'
import DateIndex from '@/components/Date/';
import BaseDialog from '@/components/Dialog/BaseDialog';
import Currency from '@/components/Currency/';
import PageInProgress from '@/components/PageInProgress.vue'

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("nested-error-message", nestedErrorMessage);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('BaseTable', BaseTable);
Vue.component('Tag', Tag);
Vue.component('RefreshPage',RefreshPage)
Vue.component('NavigateToGivenRoute', NavigateToGivenRoute);
Vue.component("DetailListWrapper", DetailListWrapper);
Vue.component("DetailListItem",DetailListItem)
Vue.component("BaseDialog", BaseDialog);
Vue.component("Date", DateIndex);
Vue.component("Currency", Currency);
Vue.component("PageInProgress", PageInProgress);
