import { getToken } from '@/util/auth'

export async function login(context, payload) {
  let url
  let cookieToken = getToken()
  if (cookieToken) {
    url = '/api/v1/auth/login/'
  } else {
    url = `${process.env.VUE_APP_SERVER_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}/api/v1/auth/login/`
  }
  try {
    let response = await global.$http.post({
      url,
      data: {
        username: payload.username,
        password: payload.password,
        account_alias_name: payload.account_alias_name,
      },
    })
    if (!response) {
      throw new Error('Unable to authenticate the user account at the moment.')
    }
    const auth_data = {
      token: response.data.token,
      username: response.data.sweet_name,
      uuid: response.data.uuid,
      BASE_API_URL: response.data.BASE_API_URL,
      HTTP_HOST: response.data.HTTP_HOST,
    }
    if (response && response.data.token) {
      context.commit('SET_AUTH_DATA', auth_data)
    }
    return auth_data
  } catch (error) {
    throw error
  }
}

export async function logout(context) {
  try {
    let cookieToken = getToken()
    if (cookieToken) {
      const url = '/api/v1/auth/logout/'
      let response = await global.$http.post({
        url,
      })
      if (!response) {
        throw new Error('Unable to logout of system at the moment.')
      }
      const { data } = response
      if (data && data.message) {
        global.$msg('success', data.message)
        context.commit('CLEAR_AUTH_DATA')
        context.commit('user/CLEAR_USER_DATA', null, {
          root: true,
        })
      }
    }
      global.$router.push('/login');
  } catch (error) {
    if (error && error.message) {
      global.$msg('error', error?.message)
    }
}
}
