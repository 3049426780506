export function SET_USER_DATA(state, userData) {
  state.user = userData;
  state.isPrimaryUser = userData.is_primary_user;
  localStorage.setItem("saas_admin_user", JSON.stringify(userData));
}
export function CLEAR_USER_DATA(state) {
  state.user = null;
  state.erp_client = null;
  localStorage.removeItem("saas_admin_user");
  localStorage.removeItem("saas_admin_erp_client");
  localStorage.removeItem("saas_admin_user_permissions");
}

export function SET_CLIENT_DATA(state, clientData) {
  state.erp_client = clientData;
  localStorage.setItem("saas_admin_erp_client", JSON.stringify(clientData));
}