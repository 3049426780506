<template>
  <span>
    {{ amount | formatAmount }}
  </span>
</template>
<script>
export default {
  name: "CurrencyIndex",
  props: ["amount"],
};
</script>
